import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Services | Clay Court Oasis
			</title>
			<meta name={"description"} content={"Crafting your ultimate tennis experience, one serve at a time"} />
			<meta property={"og:title"} content={"Services | Clay Court Oasis"} />
			<meta property={"og:description"} content={"Crafting your ultimate tennis experience, one serve at a time"} />
			<meta property={"og:image"} content={"https://volexibrix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://volexibrix.com/img/1974096.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://volexibrix.com/img/1974096.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://volexibrix.com/img/1974096.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://volexibrix.com/img/1974096.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://volexibrix.com/img/1974096.png"} />
			<meta name={"msapplication-TileImage"} content={"https://volexibrix.com/img/1974096.png"} />
			<meta name={"msapplication-TileColor"} content={"https://volexibrix.com/img/1974096.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline1"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Services
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					width="60%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					lg-width="100%"
					sm-text-align="left"
				>
					At Clay Court Oasis, we're dedicated to providing an array of top-tier services designed to enhance your tennis experience. From the novice enthusiast to the seasoned pro, our comprehensive offerings ensure that every guest finds their rhythm on the court. Dive into the details of our services below and discover all the ways we can elevate your game.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
					Court Rentals
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Pristine Clay Courts: Book your playtime on our impeccably maintained clay courts, designed for optimal performance.
					<br/><br/>
   Lighting for Night Matches: Extend your play into the evening under our eco-friendly lights.
					<br/><br/>
   Water Stations: Stay hydrated with conveniently located water stations around each court.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Equipment Rentals
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					High-Quality Gear: Access a wide range of tennis equipment, from rackets to balls, all of top-notch quality.
					<br/><br/>
   Racket Selection: Choose from a variety of rackets suitable for different play styles and skill levels.
					<br/><br/>
   Comfortable Footwear: Rent specially designed clay court shoes for better performance and safety.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Training Programs
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Personalized Coaching: Improve your game with our experienced coaches who offer personalized training sessions.
					<br/><br/>
   Group Clinics: Join our group sessions to learn in a dynamic, supportive environment.
					<br/><br/>
   Private Lessons: Opt for one-on-one coaching for focused skill enhancement.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Match Arranging
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Find Your Match: We help pair players of similar skill levels for competitive and friendly matches.
					<br/><br/>
   Social Tournaments: Participate in our regular community tournaments for fun and glory.
					<br/><br/>
   League Play: For those seeking regular competition, join one of our many tennis leagues.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Special Events
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Host Your Events: Our courts and facilities are available for private events, birthday parties, and corporate gatherings.
					<br/><br/>
   Catering Services: Enjoy customized catering options for your event, from snacks to full meals.
					<br/><br/>
   Event Planning Support: Let us assist you in organizing every detail of your event for a seamless experience.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Pro Shop
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Tennis Retail: Our on-site shop stocks the latest in tennis apparel, equipment, and accessories.
					<br/><br/>
   Expert Advice: Receive guidance from knowledgeable staff to select the best gear for your needs.
					<br/><br/>
   Equipment Maintenance: Take advantage of our racket stringing and maintenance services.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://volexibrix.com/img/5.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Discover More at Clay Court Oasis
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Interested in learning more about our services or ready to book your next tennis adventure? Contact us or visit Clay Court Oasis in person for a comprehensive guide to everything we offer. Our team is dedicated to ensuring that each visit is tailored to your needs, helping you create unforgettable tennis moments. Whether you're aiming to elevate your game, compete in friendly matches, or simply enjoy the unique experience of clay court tennis, we're here to serve you.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Elevate your game at Clay Court Oasis. Contact us to unlock your peak performance.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Get Started
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://volexibrix.com/img/6.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});